<template>
  <div class="about-container solution-container">
    <Header></Header>
    <div class="container">
      <div class="first">
        <h1 class="fs-64">行业案例</h1>
      </div>
     
      <div class="banner"></div>
      <div class="third">
        <div class="df ptb-100">
          <div><img src="../assets/img/case/c_6.jpg" alt="" /></div>
          <div>
            <h2 class="fs-38 mb-20">化工物流</h2>
            <p class="fs-20">
              深如美设（上海）供应链科技有限公司致力于为客户提供经济、安全的化工物流运输方案。
              作为中国提供危险品海运出口拼箱服务的企业之一，多年的专注积累了丰富的操作经验。
            </p>
            <p class="fs-20 mt-10 mb-10">
              我们要求每一位危险品操作人员均经过专业的培训，并持有危险化学品水路运输从业证书。
              我们将确保客户的货物自订舱至出运每一步都安全、规范、透明。
            </p>
            <p class="fs-20">
              公司设置标准的危险品仓库，拥有合法危险品仓储资质，以确保您的货物储存安全、规范、周到。
              货物查询系统，可以通过网络设备或是移动终端及时跟踪货物的基本状态，时刻确保货物安全。
            </p>
          </div>
        </div>
        <div class="df ptb-100">
          <div>
            <h2 class="fs-38 mb-20">进口 FOB</h2>
            <p class="fs-20">
              客户可以根据自身需要选择服务范围（EXW、FCA、FOB）和时间要求。
              凭借我司多维服务网络，能快速安排进口货物的定舱、报关及船期。
            </p>
            <p class="fs-20 mt-10">
              货物到港口后可根据需求进行包括换单、清关、报验、送货等门到门配送服务。
              结合我们在代理网络、拆箱、清关及内陆分拨等方面的优势，我们成为多家物流公司的合作伙伴。
            </p>
          </div>
          <div class="text-right">
            <div><img src="../assets/img/case/c_5.jpg" alt="" /></div>
          </div>
        </div>
        <div class="df ptb-100">
          <div><img src="../assets/img/case/c_3.jpg" alt="" /></div>
          <div>
            <h2 class="fs-38 mb-20">医药及农化</h2>
            <p class="fs-20">
              诸如许多医药中间体出口运输至欧盟及北美等要求非常严格的国家，我们会严格核对货物的基本信息并确保货物出运的唛头，
              危险品标识及产品包装符合国际ITATDGR的相关要求，同时也符合目的港清关的相关政策。
              我们会保存所有的流程信息、相关照片信息，在客户确认的情况下才予以出运。
              满足客户的需求进行合理规划运输方案，并确保在储存、运输及配送各个环节的安全可靠是我们对客户的承诺。
            </p>

            <!-- <p class="fs-20">Stuffing&Lashing</p>
            <p class="fs-20">Insurance</p> -->
          </div>
        </div>
        <div class="df ptb-100">
          <div>
            <h2 class="fs-38 mb-20">食品及香精</h2>
            <p class="fs-20">
              对运输食品及香精类产品而言，安全及精准的物流时间至关重要：我们致力为您提供稳定的物流配送服务,
              「守时有信」是我们营运食品及香料物流的宗旨。
              当我们接受进口或是出口委托危险产品为食品及香精类货物时我们会详细询问配送要求的时间，并提供精准的物流配送方案。
            </p>
          </div>
          <div class="text-right">
            <img src="../assets/img/case/c_1.jpg" alt="食品及香精" />
          </div>
        </div>
        <div class="df ptb-100">
          <div>
            <img src="../assets/img/case/c_4.jpg" alt="工业配件及机械设备" />
          </div>
          <div>
            <h2 class="fs-38 mb-20">工业配件及机械设备</h2>
            <p class="fs-20">
              对进口运输工业配件及机械设备而言，对货物运抵后的清关能力至关重要：
              我们致力为您提供专业的清关咨询服务，「兢兢业业」是我们营运进口工业配件及机械设备的物流宗旨。
            </p>
          </div>
        </div>
        <div class="df ptb-100">
          <div>
            <h2 class="fs-38 mb-20">服装及面料</h2>
            <p class="fs-20">
              对于进口服装及面料而言，对于运输的成本至关重要。我们致力于为您提供经济的物流配送服务。
              「精打细算」是我们营运进口服装及面料的物流宗旨。
            </p>
          </div>
          <div class="text-right">
            <img src="../assets/img/case/c_2.jpg" alt="服装及面料" />
          </div>
        </div>
  
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "../components/header";
import Footer from "../components/footer";

export default {
  name: "Solution",
  components: {
    Header,
    Footer,
  },
};
</script>
